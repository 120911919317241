import styled from '@emotion/styled';
import Logo from './logo.svg';

const LogoSVG = styled.object`
    width: 300px;
`;

export default function SPFullLogoLoader() {
    return <LogoSVG type="image/svg+xml" data={Logo} />;
}
