import styled from '@emotion/styled';
import { Logout, Menu } from '@mui/icons-material';
import Button from 'react-bootstrap/Button';
import { FAuth } from '../services/firebase/firebase';
import { useCurrentFederation } from '../contexts/CurrentFederationContext';

const Navigation = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;

    grid-column: 1 / 3;
`;

const Navbar = () => {
    const federation = useCurrentFederation();
    return (
        <Navigation className="bg-primary">
            <div className="d-flex align-items-center" style={{ flexDirection: 'row', alignItems: 'center' }}>
                <div
                    style={{
                        overflow: 'hidden',
                        width: federation ? 290 : 70,
                        padding: `0 ${federation ? '34px' : '15px'} 0 15px`,
                    }}>
                    <img
                        src={`https://drift.styreportalen.no/img/${federation ? 'logo.png' : 'logo-single.png'}`}
                        className="img-fluid"
                        alt="Styreportalen logo"
                    />
                </div>

                <Button variant="link">
                    <Menu />
                </Button>
            </div>
            <div className={'d-flex align-items-center'}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        color: 'white',
                    }}>
                    <span>{federation?.name}</span>
                    {process.env.projectId !== 'styreportalen-drift' && (
                        <span
                            style={{
                                color: 'cornflowerblue',
                            }}>
                            {process.env.projectId}
                        </span>
                    )}
                </div>
                <Button variant="primary" className="rounded-0" onClick={() => FAuth().signOut()}>
                    <Logout />
                </Button>
            </div>
        </Navigation>
    );
};

export default Navbar;
