import styled from '@emotion/styled';
import { Groups, Key } from '@mui/icons-material';
import i18next from 'i18next';
import { FC, Fragment, lazy, LazyExoticComponent, Suspense, useContext, useEffect } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, Switch } from 'react-router';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import SPLoader from '../components/SPLoader';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { GlobalUserDetails, UserSentral } from '@styreportalen/common';
import SelectFederation from '../screens/select-federation/SelectFederation';
import { FederationsContext, useUser } from '../contexts/SPUserContext';
import { CurrentFederationContext } from '../contexts/CurrentFederationContext';
import { useInitializeZoho } from '../hooks/zoho/initializeZoho';

export const enum NavigationVisibility {
    NONE,
    CONDITIONAL,
    ALWAYS,
}

export interface BaseRouterItem {
    path: string;
    exact: boolean;
    component: RouteProps['component'];
    pageTitle?: string;
    items?: RouterItem[];
    label?: string;
}

export interface AlwaysRouterItem extends BaseRouterItem {
    visibility: NavigationVisibility.ALWAYS | NavigationVisibility.CONDITIONAL;
    condition?: (path: string, user: Partial<GlobalUserDetails>, userFederation?: UserSentral) => boolean;
    label: string;
    icon?: string;
    exactPathMatch: boolean;
    adminOnly?: boolean;
}

export interface NoneRouterItem extends BaseRouterItem {
    visibility: NavigationVisibility.NONE;
}

export type RouterItem = AlwaysRouterItem | NoneRouterItem;

export const UserHasPermissionToPath = (
    path: string,
    user: Partial<GlobalUserDetails>,
    userFederation?: UserSentral,
) => {
    return user.core_access?.admin || !!userFederation?.menu[path];
};

const screenImports = {
    Organizations: {
        Organizations: () => import('../screens/organizations/OrganizationsScreen'),
        Organization: () => import('../screens/organizations/OrganizationScreen'),
    },
    Members: {
        Members: () => import('../screens/members/MembersScreen'),
        Member: () => import('../screens/members/MemberScreen'),
        MemberCompare: () => import('../screens/members/MemberCompareScreen'),
        CreateMembership: () => import('../screens/members/memberScreen/membership/CreateMembership'),
        MembersNew: () => import('../screens/members/MembersScreenNew'),
    },
    Calendar: {
        Calendar: () => import('../screens/calendar/CalendarScreen'),
        CalendarItemCreate: () => import('../screens/calendar/CalendarItemCreateScreen'),
        CalendarEvent: () => import('../screens/calendar/CalendarEventScreen'),
    },
    Files: {
        FileBrowser: () => import('../screens/files/FileArchive'),
    },
    AdditionalAgreement: {
        Agreement: () => import('../screens/additionalAgreements/AdditionalAgreementsScreen'),
    },
    MessageCenter: {
        MessageCenterCreate: () => import('../screens/messagecenter/MessageCenterCreate'),
        MessageCenterOverview: () => import('../screens/messagecenter/MessageCenterOverview'),
        MessageCenterViewMessage: () => import('../screens/messagecenter/MessageCenterViewMessage'),
    },
    AnnualReport: {
        AnnualReportOverview: () => import('../screens/annual_report/AnnualReportOverview'),
        AnnualReportStatus: () => import('../screens/annual_report/AnnualReportStatusList'),
    },
    ApplicationCenter: {
        FormOverview: () => import('../screens/applicationcenter/FormOverview'),
        AdultEducation: {
            AdultEducation: () => import('../screens/applicationcenter/adult_education/AdultEducationList'),
            AdultEducationCreate: () => import('../screens/applicationcenter/adult_education/AdultEducationScreen'),
            AdultEducationDetails: () => import('../screens/applicationcenter/adult_education/AdultEducationDetails'),
            AdultEducationStatus: () => import('../screens/applicationcenter/adult_education/AdultEducationStatusList'),
        },
        GenericForm: {
            GenericFormList: () => import('../screens/applicationcenter/generic_forms/GenericFormList'),
            GenericFormDetails: () => import('../screens/applicationcenter/generic_forms/GenericFormDetails'),
            GenericFormAdministration: () =>
                import('../screens/applicationcenter/generic_forms/GenericFormAdministration'),
        },
    },
    Setup: {
        Users: {
            Overview: () => import('../screens/users/UsersOverview'),
        },
        MailAccounts: {
            Overview: () => import('../screens/setup/MailAccountOverview'),
        },
        Access: {
            Overview: () => import('../screens/setup/access/Access'),
        },
    },
    Support: {
        Support: () => import('../screens/support/Support'),
    },
};

const waitFor: (importFn: () => Promise<any>) => LazyExoticComponent<any> = (importFn) => lazy(importFn);

// @ts-ignore
export const RouterItems: (sentralId?: string) => RouterItem[] = (sentralId) => [
    {
        path: '/organizations',
        exact: true,
        component: waitFor(screenImports.Organizations.Organizations),
        visibility: NavigationVisibility.CONDITIONAL,
        condition: UserHasPermissionToPath,
        label: i18next.t([`deviation:${sentralId}:pages:home`, 'deviation:default:pages:home']),
        icon: 'icon-home',
        exactPathMatch: false,
        items: [
            {
                path: '/organizations/:organizationId',
                exact: true,
                component: ({
                    match: {
                        params: { organizationId },
                    },
                }: RouteComponentProps<{ organizationId: string }>) => {
                    return <Redirect to={`/organizations/${organizationId}/0`} />;
                },
                visibility: NavigationVisibility.NONE,
                items: [],
            },
            {
                path: '/organizations/:organizationId/:tabId',
                exact: false,
                component: waitFor(screenImports.Organizations.Organization),
                visibility: NavigationVisibility.NONE,
                items: [],
            },
        ],
    },
    {
        path: '/members',
        exact: true,
        // component: waitFor(screenImports.Members.Members),
        component: waitFor(screenImports.Members.MembersNew),
        visibility: NavigationVisibility.CONDITIONAL,
        condition: UserHasPermissionToPath,
        // condition: () => {
        //     return !!user?.core_access?.admin;
        // },
        //adminOnly: true,
        label: i18next.t('pages:members'),
        icon: 'icon-people',
        exactPathMatch: false,
        items: [
            {
                path: '/members/:memberId',
                exact: true,
                component: waitFor(screenImports.Members.Member),
                visibility: NavigationVisibility.NONE,
                items: [],
            },
            {
                path: '/members/:memberId/compare/:dublettMemberId',
                exact: true,
                component: waitFor(screenImports.Members.MemberCompare),
                visibility: NavigationVisibility.NONE,
                items: [],
            },
            {
                path: '/members/:memberId/create-membership',
                exact: true,
                component: waitFor(screenImports.Members.CreateMembership),
                visibility: NavigationVisibility.NONE,
                items: [],
            },
        ],
    },
    {
        path: '/messagecenter',
        exact: true,
        component: waitFor(screenImports.MessageCenter.MessageCenterOverview),
        visibility: NavigationVisibility.CONDITIONAL,
        condition: UserHasPermissionToPath,
        label: i18next.t('pages:messagecenter'),
        icon: 'icon-speech',
        exactPathMatch: false,
        items: [
            {
                path: '/messagecenter/overview',
                exact: true,
                component: waitFor(screenImports.MessageCenter.MessageCenterOverview),
                visibility: NavigationVisibility.ALWAYS,
                label: 'Oversikt',
                exactPathMatch: false,
                items: [],
            },
            {
                path: '/messagecenter/create',
                exact: true,
                component: waitFor(screenImports.MessageCenter.MessageCenterCreate),
                visibility: NavigationVisibility.ALWAYS,
                label: 'Opprett Melding',
                exactPathMatch: false,
                items: [],
            },
            {
                path: '/messagecenter/message/:messageId',
                exact: true,
                component: waitFor(screenImports.MessageCenter.MessageCenterViewMessage),
                visibility: NavigationVisibility.NONE,
                items: [],
            },
        ],
    },
    {
        path: '/calendar',
        exact: true,
        component: waitFor(screenImports.Calendar.Calendar),
        visibility: NavigationVisibility.CONDITIONAL,
        condition: UserHasPermissionToPath,
        label: i18next.t('pages:calendar'),
        icon: 'icon-calendar',
        exactPathMatch: false,
        items: [
            {
                path: '/calendar/create/:date?',
                exact: true,
                component: waitFor(screenImports.Calendar.CalendarItemCreate),
                visibility: NavigationVisibility.NONE,
                items: [],
            },
            {
                path: '/calendar/activity/:eventId',
                exact: true,
                component: waitFor(screenImports.Calendar.CalendarEvent),
                visibility: NavigationVisibility.NONE,
                items: [],
            },
        ],
    },
    {
        path: '/file_archive',
        exact: true,
        component: undefined,
        visibility: NavigationVisibility.CONDITIONAL,
        condition: UserHasPermissionToPath,
        label: i18next.t('pages:files'),
        icon: 'icon-drawer',
        exactPathMatch: true,
        items: [
            {
                path: '/files',
                exact: true,
                exactPathMatch: true,
                label: i18next.t('pages:files-private'),
                component: undefined,
                visibility: NavigationVisibility.ALWAYS,
                items: [],
            },
            {
                path: '/files/shared',
                exact: true,
                exactPathMatch: true,
                label: i18next.t('pages:files-shared'),
                component: undefined,
                visibility: NavigationVisibility.ALWAYS,
                items: [],
            },
            {
                path: '/files/:rootPath*',
                exact: false,
                exactPathMatch: false,
                component: waitFor(screenImports.Files.FileBrowser),
                visibility: NavigationVisibility.NONE,
                items: [],
            },
        ],
    },
    {
        path: '/agreements',
        exact: true,
        component: waitFor(screenImports.AdditionalAgreement.Agreement),
        visibility: NavigationVisibility.CONDITIONAL,
        condition: UserHasPermissionToPath,
        label: i18next.t('pages:additionalAgreements'),
        icon: 'icon-list',
        exactPathMatch: false,
        items: [],
    },
    /* {
        path: '/messagecenter',
        exact: true,
        component: waitFor(screenImports.MessageCenter.MessageCenter),
        visibility: NavigationVisibility.CONDITIONAL,
        label: i18next.t('pages:messagecenter'),
        icon: <Message />,
        exactPathMatch: false,
        items: [],
    }, */
    {
        path: '/newsletter',
        exact: true,
        component: () => <p>Nyhetsbrev</p>,
        visibility: NavigationVisibility.CONDITIONAL,
        label: i18next.t('pages:newsletter'),
        icon: 'icon-speech',
        exactPathMatch: false,
        items: [],
    },
    {
        path: '/aarsrapport',
        exact: true,
        component: waitFor(screenImports.AnnualReport.AnnualReportOverview),
        visibility: NavigationVisibility.NONE,
        label: 'Årsrapport',
        icon: 'icon-speech',
        exactPathMatch: false,
        items: [
            {
                path: '/aarsrapport/status',
                exact: true,
                component: waitFor(screenImports.AnnualReport.AnnualReportStatus),
                visibility: NavigationVisibility.NONE,
                items: [],
            },
        ],
    },
    {
        path: '/applicationcenter',
        exact: true,
        component: waitFor(screenImports.ApplicationCenter.FormOverview),
        visibility: NavigationVisibility.CONDITIONAL,
        condition: UserHasPermissionToPath,
        label: i18next.t('pages:applicationcenter'),
        icon: 'icon-docs',
        exactPathMatch: false,
        items: [
            {
                path: '/applicationcenter/adulteducation',
                exact: true,
                component: waitFor(screenImports.ApplicationCenter.AdultEducation.AdultEducation),
                visibility: NavigationVisibility.NONE,
                exactPathMatch: false,
                items: [
                    {
                        path: '/applicationcenter/adulteducation/details/:submissionId',
                        exact: true,
                        component: waitFor(screenImports.ApplicationCenter.AdultEducation.AdultEducationDetails),
                        visibility: NavigationVisibility.NONE,
                        exactPathMatch: true,
                        items: [],
                    },
                    {
                        path: '/applicationcenter/adulteducation/create',
                        exact: true,
                        component: waitFor(screenImports.ApplicationCenter.AdultEducation.AdultEducationCreate),
                        visibility: NavigationVisibility.NONE,
                        exactPathMatch: true,
                        items: [],
                    },
                    {
                        path: '/applicationcenter/adulteducation/status',
                        exact: true,
                        component: waitFor(screenImports.ApplicationCenter.AdultEducation.AdultEducationStatus),
                        visibility: NavigationVisibility.NONE,
                        exactPathMatch: true,
                        items: [],
                    },
                ],
            },
            {
                path: '/applicationcenter/generic/:formId',
                exact: true,
                component: waitFor(screenImports.ApplicationCenter.GenericForm.GenericFormList),
                visibility: NavigationVisibility.NONE,
                exactPathMatch: true,
                items: [
                    {
                        path: '/applicationcenter/generic/:formId/details/:submissionId',
                        exact: true,
                        component: waitFor(screenImports.ApplicationCenter.GenericForm.GenericFormDetails),
                        visibility: NavigationVisibility.NONE,
                        exactPathMatch: true,
                        items: [],
                    },
                    {
                        path: '/applicationcenter/generic/:formId/add',
                        exact: true,
                        component: waitFor(screenImports.ApplicationCenter.GenericForm.GenericFormDetails),
                        visibility: NavigationVisibility.NONE,
                        exactPathMatch: true,
                        items: [],
                    },
                    {
                        path: '/applicationcenter/generic/:formId/administration',
                        exact: true,
                        component: waitFor(screenImports.ApplicationCenter.GenericForm.GenericFormAdministration),
                        visibility: NavigationVisibility.NONE,
                        exactPathMatch: true,
                        items: [],
                    },
                    // {
                    //     path: '/applicationcenter/adulteducation/create',
                    //     exact: true,
                    //     component: AdultEducationScreen,
                    //     visibility: NavigationVisibility.NONE,
                    //     exactPathMatch: true,
                    //     items: [],
                    // },
                ],
            },
        ],
    },
    {
        path: '/setup',
        exact: true,
        component: () => null,
        visibility: NavigationVisibility.CONDITIONAL,
        condition: UserHasPermissionToPath,
        label: i18next.t('pages:setup'),
        icon: 'icon-settings',
        exactPathMatch: false,
        items: [
            {
                path: '/setup/regions',
                exact: true,
                component: () => <p>Regionoppsett</p>,
                visibility: NavigationVisibility.ALWAYS,
                label: i18next.t('pages:regions'),
                iconName: <Groups />,
                exactPathMatch: false,
                items: [],
            },
            {
                path: '/setup/users',
                exact: true,
                component: waitFor(screenImports.Setup.Users.Overview),
                visibility: NavigationVisibility.ALWAYS,
                label: i18next.t('pages:users'),
                exactPathMatch: false,
                items: [],
            },
            {
                path: '/setup/mail-account',
                exact: true,
                component: waitFor(screenImports.Setup.MailAccounts.Overview),
                visibility: NavigationVisibility.ALWAYS,
                label: i18next.t('pages:mail_accounts'),
                exactPathMatch: false,
                items: [],
            },
            {
                path: '/setup/access',
                exact: true,
                component: waitFor(screenImports.Setup.Access.Overview),
                visibility: NavigationVisibility.ALWAYS,
                label: i18next.t('pages:access'),
                iconName: <Key />,
                exactPathMatch: false,
                items: [],
            },
        ],
    },
    {
        path: '/support',
        exact: true,
        component: waitFor(screenImports.Support.Support),
        visibility: NavigationVisibility.ALWAYS,
        label: i18next.t('pages:support'),
        icon: 'icon-support',
        exactPathMatch: false,
        items: [],
    },
];

const mapRouterItem = (routerItem: RouterItem) => {
    const baseRoute = <Route path={routerItem.path} component={routerItem.component} exact={routerItem.exact} />;

    if (routerItem.items) {
        return (
            <Fragment key={uuid()}>
                {baseRoute}
                {routerItem.items.map(mapRouterItem)}
            </Fragment>
        );
    }

    return baseRoute;
};

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;

    overflow-y: auto;
    overflow-x: hidden;
`;

const Page = styled.div`
    display: grid;
    grid-template-columns: 220px auto;
    grid-template-rows: 60px auto;
    background-color: #f5f7fa;
    height: 100vh;
`;

const Router: FC = () => {
    return (
        <Page>
            <Navbar />
            <Sidebar />
            <ContentContainer>
                <Suspense fallback={<SPLoader />}>
                    {RouterItems().map(mapRouterItem)}
                    <Route path="/" exact component={() => <p>Velkommen til Styreportalens sentralportal!</p>} />
                </Suspense>
            </ContentContainer>
        </Page>
    );
};

interface SentralizedRouterParams {
    sentralId: string;
}

const SentralizedRouter: FC<RouteComponentProps<SentralizedRouterParams>> = (props) => {
    const { sentralId } = props.match.params;
    const federations = useContext(FederationsContext);
    const history = useHistory();
    useEffect(() => {
        if (!federations?.[sentralId]) {
            history.replace('/');
        }
    }, [sentralId, federations, history]);
    const user = useUser();
    useInitializeZoho(user, sentralId);

    return (
        <BrowserRouter basename={'/' + sentralId}>
            <CurrentFederationContext selectedId={sentralId}>
                <Switch>
                    <Router />
                </Switch>
            </CurrentFederationContext>
        </BrowserRouter>
    );
};

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/select-federation" component={SelectFederation} />
                <Route path="/:sentralId" component={SentralizedRouter} />
                <Route exact path="/">
                    <Redirect to="/select-federation" />
                </Route>
            </Switch>
        </BrowserRouter>
    );
};

export default AppRouter;
