import * as english from '../lang/en';
import * as norwegian from '../lang/no';
export const fallback = 'nb';

type Locale = typeof english;

export const supportedLocales: Record<
    string,
    {
        name: string;
        translationFileLoader: () => Locale;
        momentLocale: string;
    }
> = {
    en: {
        name: 'English',
        translationFileLoader: () => english,

        momentLocale: 'en-gb',
    },
    nb: {
        name: 'Norwegian',
        translationFileLoader: () => norwegian,
        momentLocale: 'nb',
    },
};

export const defaultNamespace = 'common';

export const namespaces = [
    'deviation',
    'common',
    'login',
    'file_archive',
    'pages',
    'countries',
    'assets',
    'info',
    'profile',
    'post',
    'event',
    'firebase_error',
    'feedback',
    'action',
    'settings',
    'organizations',
    'forms',
    'support',
    'utility',
    'components',
    'access',
];
