import { css } from '@emotion/react';
import { useState } from 'react';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import SPLoader from '../../components/SPLoader';
import { login } from '../../services/auth';
import firebase from 'firebase/app';
import i18next from 'i18next';
import ForgotScreen from './ForgotScreen';

export default function LoginScreen() {
    const [state, setState] = useState<{ executing: boolean; email: string; password: string; error?: string }>({
        executing: false,
        email: '',
        password: '',
    });

    const [forgotPass, setForgotPass] = useState<boolean>(false);

    const executeLogin = async () => {
        if (state.executing) {
            return;
        }

        setState({ ...state, executing: true });
        try {
            await login(state.email, state.password);
        } catch (e) {
            setState({ ...state, error: (e as firebase.FirebaseError).code, executing: false });
        }
    };

    if (forgotPass) {
        return <ForgotScreen setForgotPass={setForgotPass} />;
    }

    return (
        <div
            css={css`
                display: flex;
                justify-content: center;
                margin-top: 1rem;
            `}>
            <Card
                css={css`
                    max-width: 60ch;
                    width: 100%;
                `}>
                <Card.Header className="logoBox">
                    <img
                        src="https://drift.styreportalen.no/img/logo.png"
                        className="img-fluid px-4 py-3"
                        alt="Styreportalen logo"
                    />
                </Card.Header>
                <Card.Body>
                    {state.executing && (
                        <div className="d-flex justify-content-center">
                            <SPLoader />
                        </div>
                    )}

                    {state.error && <Alert variant="danger">{i18next.t('firebase_error:' + state.error)}</Alert>}

                    <form
                        className="d-flex flex-column gap-3"
                        onSubmit={(e) => {
                            e.preventDefault();
                            executeLogin();
                        }}>
                        <Form.Group>
                            <Form.Label>{i18next.t('login:email_address')}</Form.Label>
                            <Form.Control
                                autoComplete="email"
                                placeholder={i18next.t('login:email_address')}
                                disabled={state.executing}
                                type="email"
                                value={state.email}
                                onChange={({ target: { value } }) => setState({ ...state, email: value })}
                            />
                        </Form.Group>

                        <Form.Group className="mb-2">
                            <Form.Label>{i18next.t('login:password')}</Form.Label>
                            <Form.Control
                                autoComplete="current-password"
                                placeholder={i18next.t('login:password')}
                                disabled={state.executing}
                                type="password"
                                value={state.password}
                                onChange={({ target: { value } }) => setState({ ...state, password: value })}
                            />
                        </Form.Group>

                        <div className="d-flex justify-content-between">
                            <Button type="button" variant="link" onClick={() => setForgotPass(true)}>
                                {i18next.t('login:forgot_password')}
                            </Button>
                            <Button type="submit" disabled={state.executing}>
                                {i18next.t('login:sign_in')}
                            </Button>
                        </div>
                    </form>
                </Card.Body>
            </Card>
        </div>
    );
}
